<mat-toolbar class="mat-toolbar">
    <mat-toolbar-row class="d-flex justify-content-between">
        <h3 mat-dialog-title class="brick-title">Resistencia térmica de mampostería de ladrillos y bloques cerámicos</h3>
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>


<div mat-dialog-content >

    <div class="example-container mat-elevation-z8">

        <mat-radio-group [(ngModel)]="selectedElement">

            <table mat-table [dataSource]="dataSource">

                <!-- Selected Column -->
                <ng-container matColumnDef="selected">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'BEEC.BUILDINGENV_DETAILS.RVALUE_SELECTED_ELEMENT' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-radio-button [value]="element" (click)="selectedR($event, element)">
                        </mat-radio-button>
                    </td>
                </ng-container>


                <ng-container matColumnDef="Esquema">
                    <th style="font-size: x-large;" mat-header-cell *matHeaderCellDef> Esquema </th>
                    <td mat-cell *matCellDef="let element">
                        <img src="{{element.brickimg}}">
                    </td>
                </ng-container>

                <ng-container matColumnDef="Material">
                    <th style="font-size: x-large;" mat-header-cell *matHeaderCellDef> Material </th>
                    <td mat-cell *matCellDef="let element"> {{element.material}} </td>
                </ng-container>

                <ng-container matColumnDef="Densidad">
                    <th style="font-size: x-large;" mat-header-cell *matHeaderCellDef> Densidad </th>
                    <td mat-cell *matCellDef="let element"> {{element.density}} </td>
                </ng-container>

                <ng-container matColumnDef="e">
                    <th style="font-size: x-large;" mat-header-cell *matHeaderCellDef> e </th>
                    <td mat-cell *matCellDef="let element"> {{element.emeasure}} </td>
                </ng-container>

                <ng-container matColumnDef="h">
                    <th style="font-size: x-large;" mat-header-cell *matHeaderCellDef> h </th>
                    <td mat-cell *matCellDef="let element"> {{element.hmeasure}} </td>
                </ng-container>

                <ng-container matColumnDef="l">
                    <th style="font-size: x-large;" mat-header-cell *matHeaderCellDef> l </th>
                    <td mat-cell *matCellDef="let element"> {{element.lmeasure}} </td>
                </ng-container>


                <ng-container matColumnDef="masa">
                    <th style="font-size: x-large;" mat-header-cell *matHeaderCellDef> masa </th>
                    <td mat-cell *matCellDef="let element"> {{element.mass}} </td>
                </ng-container>

                <ng-container matColumnDef="R">
                    <th style="font-size: x-large;" mat-header-cell *matHeaderCellDef> R </th>
                    <td mat-cell *matCellDef="let element"> {{element.rvalue}} </td>
                </ng-container>

                <ng-container matColumnDef="header-row-first-group">
                    <td mat-header-cell class="th-custom-border-cell" *matHeaderCellDef [attr.rowspan]="2">Esquema</td>
                </ng-container>
                <ng-container matColumnDef="header-row-first-Material">
                    <td mat-header-cell class="th-custom-border-cell" *matHeaderCellDef [attr.rowspan]="2">Material</td>
                </ng-container>
                <ng-container matColumnDef="header-row-first-Densidad">
                    <td mat-header-cell class="th-custom-border-cell" *matHeaderCellDef [attr.rowspan]="2">Densidad</td>
                </ng-container>
                <ng-container matColumnDef="header-row-first-masa">
                    <td mat-header-cell class="th-custom-border-cell" *matHeaderCellDef [attr.rowspan]="2">Masa</td>
                </ng-container>
                <ng-container matColumnDef="header-row-first-R">
                    <td mat-header-cell class="th-custom-border-cell" *matHeaderCellDef [attr.rowspan]="2">R</td>
                </ng-container>
                <ng-container matColumnDef="header-row-first-selected">
                    <td mat-header-cell class="th-custom-border-cell" *matHeaderCellDef [attr.rowspan]="2">selected</td>
                </ng-container>
                <ng-container matColumnDef="header-row-third-group">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"> Medidas(cm)</th>
                </ng-container>


                <tr mat-header-row
                    *matHeaderRowDef="['header-row-first-group','header-row-first-Material',
                    'header-row-first-Densidad','header-row-third-group','header-row-first-masa',
                    'header-row-first-R','header-row-first-selected'];sticky: true">
                </tr>
                <tr mat-header-row *matHeaderRowDef="lastDisplayedCols; sticky: true"></tr>


                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </mat-radio-group>

    </div>
</div>