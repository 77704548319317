<mat-toolbar class="mat-toolbar">
    <mat-toolbar-row class="d-flex justify-content-between">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>



<div class="container" style="border-style: ridge;padding-top: 1rem;">
    <div id="pdf-general-section">
        <div class="row" style="padding-top: 1rem;">

            <div class="col-md-2"><img src="../../../assets/images/report/argentinaflag.png">
            </div>
            <div class="col-md-3">
                <ul class="header-pdf" style="list-style: none;">
                    <li style="font-size: xx-large;font-weight: bold; padding-bottom: 1rem; ">
                        {{selectedcountryname}}
                    </li>
                    <li style="font-weight: bold;">
                        {{'BEEC.GENDETAILS.LOC_DETAILS_PROVINCE' | translate}} :
                        {{genDetailsComponent.genDetailsForm.controls.province.value}}

                    </li>
                    <li style="font-weight: bold;">
                        {{'BEEC.GENDETAILS.LOC_DETAILS_LOCATION' | translate}} :
                        {{genDetailsComponent.genDetailsForm.controls.location.value}}

                    </li>
                </ul>
            </div>

            <div class="offset-md-3"> </div>
            <div class="col-md-2"><img src="../../../assets/images/Logos/UNEP-partnershiplogo.png" style="width: 100%;" style="visibility: hidden;">
            </div>
            <div class="col-md-2"><img src="../../../assets/images/c2e2-logo-seforall.png" style=" width: 100%;">
            </div>
        </div>


        <div class="report-title">
            <p>{{'BEEC.REPORT.PDF_TITLE' | translate}}</p>
        </div>


        <div style="display: flex;">
            <div>
                <img src="../../../assets/images/report/plughouse.png">
            </div><br>
            <div style="display: flex;flex-direction: column;align-items: flex-end;">
                <div>
                    <ul class="header-pdf" style="list-style: none;">
                        <li style="font-weight: bold;">
                            {{'BEEC.REPORT.STUDY_REPORT' | translate}}
                        </li>
                        <li style="font-weight: bold;">
                            {{'BEEC.REPORT.ISSUED_ON' | translate}} : {{curDate | date:'MMM d, y'}}
                        </li>
                        <li style="font-weight: bold;" *ngIf="beetReportComponent.pdfInputForm.value == true">
                            {{'BEEC.REPORT.USER_NAME' | translate}} :
                            {{beetReportComponent.beetReportForm.controls.userName.value}}

                        </li>
                    </ul>

                </div>
                <div style="font-size: smaller;">{{'BEEC.REPORT.PDF_TEXT' | translate}} <br>
                   <!--  <b> please visit: www.c2e2.une</b> <b>contact us: c2e2@dtu.dk / rradu@dtu.dk</b> -->
                </div>
            </div>
        </div>

        <div class="report-subtitle">
            <p>{{'BEEC.COMMON.STEPLABEL1' | translate}} </p>
        </div>

        <div *ngIf="beetReportComponent.pdfInputForm.value == true" style="padding-left: 2rem;">
            <td style="font-weight: bold;">{{'BEEC.REPORT.PROJECT_NAME' | translate}} :</td>
            <td>{{beetReportComponent.beetReportForm.controls.projectName.value}}
            </td>
        </div>

        <div style="display: flex;flex-wrap: wrap; overflow: hidden;">
            <table class="table table-hover" style="flex: 1 1 20em;
        border-top: 1px solid 	rgb (0,0,0);
        border-left: 1px solid 	rgb (0,0,0);
        margin-top: -1px;
        margin-left: -1px;">



                <tbody>
                    <tr>
                        <td style="font-weight: bold;">{{ 'BEEC.GENDETAILS.BUILDING_DETAILS_TYPE' | translate}} :</td>

                        <td>{{genDetailsComponent.genDetailsForm.controls.buildingType.value}}
                        </td>
                    </tr>
                    <tr *ngIf="beetReportComponent.pdfInputForm.value == true">
                        <td style="font-weight: bold;">{{'BEEC.REPORT.YEAROFCONSTRUCTION' | translate}} :</td>

                        <td>{{beetReportComponent.beetReportForm.controls.yearOfConstruction.value}}
                        </td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold;">{{ 'BEEC.REPORT.SURFACE_AREA' | translate}} : </td>

                        <td>{{genDetailsComponent.genDetailsForm.controls.netOccupiedFloorArea.value|
                            number:'1.2-2'}}
                            {{genDetailsComponent.genDetailsForm.controls.netAreaUnits.value}}
                        </td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold;">{{ 'BEEC.REPORT.NUMBER_OF_FLOORS' | translate}} : </td>
                        <td>{{genDetailsComponent.genDetailsForm.controls.noOfFloors.value}}
                        </td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold;">{{ 'BEEC.REPORT.OCCUPANCY_HOURS' | translate}}s : </td>
                        <td>{{genDetailsComponent.genDetailsForm.controls.occupanyHoursPerWeek.value}}
                        </td>
                    </tr>
                </tbody>
            </table>

            <table class="table table-hover" style="flex: 1 1 20em;
        border-top: 1px solid 	rgb (0,0,0);
        border-left: 1px solid 	rgb (0,0,0);
        margin-top: -1px;
        margin-left: -1px;">
                <tbody>
                    <tr>
                        <td style="font-weight: bold;">{{'BEEC.GENDETAILS.OCCUPANCY_OCCUPANCYDENSITY' |
                            translate}} :</td>
                        <td> {{genDetailsComponent.genDetailsForm.controls.occupantDensityKnown.value|
                            number:'1.2-2'}}
                            {{genDetailsComponent.genDetailsForm.controls.occupantDensityUnits.value}}
                        </td>
                    </tr>

                    <tr>
                        <td style="font-weight: bold;">{{'BEEC.REPORT.ELECTRICITY_COST' | translate}} :</td>

                        <td>{{genDetailsComponent.genDetailsForm.controls.electricityCost.value|
                            number:'1.2-2'}}
                            {{genDetailsComponent.genDetailsForm.controls.electricityUnits.value}}
                        </td>
                    </tr>

                    <tr>
                        <td style="font-weight: bold;">{{'BEEC.REPORT.FUEL_COST' | translate}} : </td>
                        <td>{{genDetailsComponent.genDetailsForm.controls.fuelCost.value|
                            number:'1.2-2'}}
                            {{genDetailsComponent.genDetailsForm.controls.fuelUnits.value}}</td>
                    </tr>
                </tbody>
            </table>
        </div>


        <div>
            <div>
                <p class="report-subtitle">{{'BEEC.REPORT.KEY_INDICATORS' | translate}}</p>
            </div>

            <div class="row">

                <div class="container-fluid mt-5 col-md-6">
                    <b>{{'BEEC.REPORT.ANNUAL_PERFORMANCE_INDICES' | translate}}</b>
                    <div class="chart-wrapper">
                        <canvas baseChart [datasets]="parentData.barChartData" [labels]="barChartLabels"
                            [options]="barChartOptions1" [plugins]="barChartPlugins" [legend]="barChartLegend"
                            [chartType]="barChartType" [colors]="barChartColors">
                        </canvas>
                    </div>
                </div>

                <div class="container-fluid mt-5 col-md-6">
                    <b>{{'BEEC.REPORT.EQUIVALENT_ENERGY_CONSUMPTION' | translate}}</b>
                    <div class="chart-wrapper">
                        <canvas baseChart [datasets]="parentData.barChartData4" [labels]="barChartLabels4"
                            [options]="barChartOptions4" [plugins]="barChartPlugins" [legend]="barChartLegend"
                            [chartType]="barChartType" [colors]="barChartColors">
                        </canvas>
                    </div>
                </div>

                <div class="container-fluid mt-5 col-md-6">
                    <b>{{'BEEC.REPORT.ANNUAL_ENERY_COST' | translate}}</b>
                    <div class="chart-wrapper">
                        <canvas baseChart [datasets]="parentData.barChartData2" [labels]="barChartLabels2"
                            [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
                            [chartType]="barChartType" [colors]="barChartColors">
                        </canvas>
                    </div>
                </div>

                <div class="container-fluid mt-5 col-md-6">
                    <b>{{'BEEC.REPORT.EQUIVALENTCO2EMISSIONS' | translate}}
                    </b>
                    <div class="chart-wrapper">
                        <canvas baseChart [datasets]="parentData.barChartData3" [labels]="barChartLabels3"
                            [options]="barChartOptions3" [plugins]="barChartPlugins" [legend]="barChartLegend"
                            [chartType]="barChartType" [colors]="barChartColors">
                        </canvas>
                    </div>
                </div>

                <br>

                <div class="key-indicators-table" style="display:flex">
                    <table class="table table-hover mt-5 col-md-5" style="flex: 1 1 20em;
            border-top: 1px solid 	rgb (0,0,0);
            border-left: 1px solid 	rgb (0,0,0);
            margin-top: -1px;
            margin-left: -1px;">
                        <thead>
                            <tr>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="key-indicators">{{'BEEC.REPORT.PEAK_ELECTRICITY_DEMAND_IN' | translate}}</td>
                                <td class="key-data-values">{{parentData.peakelectricitydemand}}</td>
                            </tr>
                            <tr>

                                <td class="key-indicators">{{'BEEC.REPORT.HIGHEST_ELECTRICITY_CONSUMPTION_IN' |
                                    translate}}</td>
                                <td class="key-data-values">{{parentData.highestelectricityconsumption}}</td>
                            </tr>
                            <tr>

                                <td class="key-indicators">{{'BEEC.REPORT.HIGHEST_FUEL_CONSUMPTION_FORHEATINGIN' |
                                    translate}}</td>
                                <td class="key-data-values">{{parentData.highestfuelconsumption}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table table-hover mt-5 col-md-8" style="flex: 1 1 20em;
            border-top: 1px solid 	rgb (0,0,0);
            border-left: 1px solid 	rgb (0,0,0);
            margin-top: -1px;
            margin-left: -1px;">
                        <thead>
                            <tr>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="key-indicators">
                                    <img style="width: 30px"
                                        src="../../../assets/images/report/esavings.png">{{'BEEC.REPORT.ENERGY_SAVINGS'
                                    |
                                    translate}}
                                </td>
                                <td class="key-data-values">{{parentData.energysavings |
                                    number:'1.2-2'}}{{parentData.energysavingsunit}}</td>
                                <td class="key-data-percentvalues">{{parentData.energysavingspercent | number:'1.2-2'}}
                                    <img style="width: 22px" src="../../../assets/images/report/green-arrow.png">
                                </td>
                            </tr>
                            <tr>

                                <td class="key-indicators">

                                    <img style="width: 30px"
                                        src="../../../assets/images/report/ecsavings.png">{{'BEEC.REPORT.ENERGY_COST_SAVINGS'
                                    | translate}}
                                    Savings
                                </td>
                                <td class="key-data-values">{{parentData.energycostsavings |
                                    number:'1.2-2'}}{{parentData.energycostsavingsunit}}</td>
                                <td class="key-data-percentvalues">{{parentData.energycostsavingspercent |
                                    number:'1.2-2'}}
                                    <img style="width: 22px" src="../../../assets/images/report/green-arrow.png">
                                </td>
                            </tr>
                            <tr>

                                <td class="key-indicators">

                                    <img style="width: 40px"
                                        src="../../../assets/images/report/co2red.png">{{'BEEC.REPORT.CO2_REDUCTION'
                                    | translate}}
                                </td>
                                <td class="key-data-values">{{parentData.co2reduction | number:'1.2-2'}}
                                    {{parentData.co2reductionunit}}</td>
                                <td class="key-data-percentvalues">{{parentData.co2reductionpercent | number:'1.2-2'}}
                                    <img style="width: 22px" src="../../../assets/images/report/green-arrow.png">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>



    <div class="container" id="pdf-construction">
        <div class="report-subtitle">
            <p>{{'BEEC.REPORT.BASELINE_EEPOTENTIALIMPROVEMENTS' | translate}}</p>
        </div><br>
        <div>
            <table class="table  table-hover table-bordered table-sm" data-toggle="table">
                <thead>
                    <tr>
                        <th colspan="5" style="background-color: #2F75B5;">{{ 'BEEC.REPORT.CONSTRUCTION_ELEMENT' |
                            translate}}</th>

                    </tr>
                    <tr>
                        <th colspan="3" style="background-color: #F4B084;">{{ 'BEEC.REPORT.BASELINE' | translate}}</th>
                        <th colspan="4" style="background-color: #A9D08E;">{{ 'BEEC.REPORT.ENERGY_EFFICIENCY_STANDARDS'
                            | translate}}</th>                    </tr>
                    <tr>
                        <th colspan="2" style="background-color: #FCE4D6;">{{ 'BEEC.REPORT.ELEMENT' | translate}}</th>
                        <th style="background-color: #FCE4D6;">{{ 'BEEC.REPORT.INDICATORS' | translate}}</th>
                        <th style="background-color: #E2EFDA;">{{ 'BEEC.REPORT.INDICATORS' | translate}}</th>
                        <th style="background-color: #E2EFDA;">{{ 'BEEC.REPORT.RECOMMENDATIONS' | translate}}</th>
                    </tr>

                    <tr>
                        <th colspan="6" style="background-color: #F2F2F2;">{{ 'BEEC.REPORT.MAIN_FACADE' | translate}}
                        </th>
                    </tr>

                    <tr>
                        <td colspan="2" style="width: 40%;">
                            <p *ngIf="buildingdetailsComponent?.formgroup.controls.outerwallr.value == 1"
                                style="font-size: large;font-weight: bold;text-align: center;">
                                User Input Value
                            </p>
                            <img *ngIf="buildingdetailsComponent?.formgroup.controls.outerwallr.value == 2"
                                style="width: 100%;"
                                [src]="buildingdetailsComponent?.formgroup.get('outerWallArray')['controls'][0].controls.rimages.value.imagepath" />
                            <p *ngIf="buildingdetailsComponent?.formgroup.controls.outerwallr.value == 3"
                                style="font-size: large;font-weight: bold;text-align: center;">
                                User Custom Built Layer</p>
                        </td>
                        <td style="color: red;">{{parentData.wallrvaluebaseline |
                            number:'1.2-2'}}
                            {{parentData.wallrvalueunit}}</td>
                        <td></td>
                        <td>
                            <ul>
                                <li *ngFor="let rec of parentData.wallrecommendations;
                                    let i = index">
                                    {{ rec.recommendation}}
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th style="background-color: #F2F2F2;" colspan="6">{{ 'BEEC.REPORT.ROOF' | translate}}</th>
                    </tr>

                    <tr>
                        <td colspan="2" style="width: 40%;">
                            <p *ngIf="buildingdetailsComponent?.formgroup.controls.roofr.value == 1"
                                style="font-size: large;font-weight: bold;text-align: center;">
                                User Input Value</p>
                            <img *ngIf="buildingdetailsComponent?.formgroup.controls.roofr.value == 2"
                                style="width: 100%;"
                                [src]="buildingdetailsComponent?.formgroup.get('roofrArray')['controls'][0].controls.roofrimages.value.imagepath" />
                            <p *ngIf="buildingdetailsComponent?.formgroup.controls.roofr.value == 3"
                                style="font-size: large;font-weight: bold;text-align: center;">
                                User Custom Built Layer</p>
                        </td>
                        <td style="color: red;">{{parentData.roofrvaluebaseline |
                            number:'1.2-2'}}
                            {{parentData.roofrvalueunit}}</td>
                        <td></td>
                        <td>
                            <ul>
                                <li *ngFor="let rec of parentData.roofrecommendations;
                                        let i = index">
                                    <p>{{ rec.recommendation}}</p>
                                </li>
                            </ul>
                        </td>
                    </tr>


                    <tr>
                        <th colspan="6" style="background-color: #F2F2F2;">{{ 'BEEC.REPORT.WINDOWS' | translate}}</th>
                    </tr>

                    <tr style="width: 40%;">
                        <td rowspan="3"> <img style="width: 100%;"
                                src="../../../assets/images/report/window-report.png" />
                        </td>
                        <td>SHGC</td>
                        <td style="color: red;">{{parentData.windowshgcvaluebaseline }}</td>
                        <td style="color: green">{{parentData.windowshgcvalueees }}</td>
                        <td rowspan="3">
                            <ul>
                                <li *ngFor="let rec of parentData.windowrecommendations;
                                    let i = index">
                                    <p> {{ rec.recommendation}}</p>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>WWR</td>
                        <td style="color: red;">{{parentData.wwrvaluebaseline }}
                            {{parentData.wwrvalueunit}}</td>
                        <td style="color: green">{{parentData.wwrvalueees}}
                            {{parentData.wwrvalueunit}}</td>
                    </tr>

                    <tr>
                        <td>R value</td>
                        <td style="color: red;">{{parentData.windowrvaluebaseline |
                            number:'1.2-2'}}
                            {{parentData.windowrvalueunit}}</td>
                        <td style="color: green">{{parentData.windowrvalueees | number:'1.2-2'}}
                            {{parentData.windowrvalueunit}}</td>
                    </tr>

                    <tr>
                        <th colspan="5" style="background-color: #2F75B5;">{{ 'BEEC.REPORT.HVAC_SYSTEMS' | translate}}
                        </th>
                    </tr>
                    <tr>
                        <th colspan="3" style="background-color: #F4B084;">{{ 'BEEC.REPORT.BASELINE' | translate}}</th>
                        <th colspan="2" style="background-color: #A9D08E;">{{ 'BEEC.REPORT.ENERGY_EFFICIENCY_STANDARDS'
                            | translate}}</th>

                    </tr>
                    <tr>
                        <th colspan="2" style="background-color: #FCE4D6;">{{ 'BEEC.REPORT.SYSTEM' | translate}}</th>
                        <th style="background-color: #FCE4D6;">{{ 'BEEC.REPORT.PERFORMANCE' | translate}}</th>
                        <th style="background-color: #E2EFDA;">{{ 'BEEC.REPORT.PERFORMANCE' | translate}}</th>
                        <th style="background-color: #E2EFDA;">{{ 'BEEC.REPORT.RECOMMENDATIONS' | translate}}</th>
                    </tr>

                    <tr>
                        <th colspan="5" style="background-color: #F2F2F2;">{{ 'BEEC.REPORT.HEATING' | translate}}</th>
                    </tr>

                    <tr>
                        <td colspan="2">
                            <img style="width: 100%;" [src]="parentData.heatingefficiencyimage" />
                        </td>
                        <td style="color: red;">
                            {{parentData.heatingefficiencybaseline}}{{parentData.heatingefficiencyunit}}
                        </td>
                        <td style="color: green">
                            {{parentData.heatingefficiencyees}}{{parentData.heatingefficiencyunit}}
                        </td>


                        <td>
                            <ul>
                                <li *ngFor="let rec of parentData.heatingefficiencyrecommendations;
                                            let i = index">
                                    <p>{{ rec.recommendation}}</p>
                                </li>
                            </ul>
                        </td>
                    </tr>

                    <tr>
                        <th colspan="5" style="background-color: #F2F2F2;">{{ 'BEEC.REPORT.COOLING' | translate}}</th>
                    </tr>

                    <tr>
                        <td colspan="2">
                            <img style="width: 100%;" [src]="parentData.coolingefficiencyimage" />
                        </td>
                        <td style="color: red;">
                            {{parentData.coolingefficiencybaseline}} {{parentData.coolingefficiencyunit}}
                        </td>
                        <td style="color: green;">
                            {{parentData.coolingefficiencyees | number:'1.2-2'}}
                            {{parentData.coolingefficiencyunit}}
                        </td>


                        <td>
                            <ul>
                                <li *ngFor="let rec of parentData.coolingefficiencyrecommendations;
                                            let i = index">
                                    <p> {{ rec.recommendation}}</p>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="5" style="background-color: #F2F2F2;">{{ 'BEEC.REPORT.FANS_AND_BLOWERS' |
                            translate}}</th>
                    </tr>

                    <tr>
                        <td colspan="2"> <img style="width: 100%;"
                                src="../../../assets/images/report/fansblowers-report.png" />
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                            <ul>
                                <li *ngFor="let rec of parentData.fansblowersrecommendations;
                                            let i = index">
                                    <p> {{ rec.recommendation}}</p>
                                </li>
                            </ul>
                        </td>
                    </tr>

                   
                </thead>
            </table>
        </div>
    </div>

    <div class="container" id="pdf-hvac">
        <!-- <div class="report-subtitle">
            <p>{{'BEEC.REPORT.BASELINE_EEPOTENTIALIMPROVEMENTS' | translate}}</p>
        </div><br> -->
        <div>
            <table class="table  table-bordered table-sm" data-toggle="table">
                <thead>
    
                    <tr>
                        <th colspan="4" style="background-color: #2F75B5;">{{ 'BEEC.REPORT.LIGHTING_HEADING' |
                            translate}}</th>
                    </tr>
                    <tr>
                        <th colspan="2" style="background-color: #F4B084;">{{ 'BEEC.REPORT.BASELINE' | translate}}</th>
                        <th colspan="2" style="background-color: #A9D08E;">{{ 'BEEC.REPORT.ENERGY_EFFICIENCY_STANDARDS'
                            | translate}}</th>

                    </tr>
                    <tr>
                        <th style="background-color: #FCE4D6;">{{ 'BEEC.REPORT.SYSTEM' | translate}}</th>
                        <th style="background-color: #FCE4D6;">{{ 'BEEC.REPORT.PERFORMANCE' | translate}}</th>
                        <th style="background-color: #E2EFDA;">{{ 'BEEC.REPORT.PERFORMANCE' | translate}}</th>
                        <th style="background-color: #E2EFDA;">{{ 'BEEC.REPORT.RECOMMENDATIONS' | translate}}</th>
                    </tr>
                   <!--  <tr>
                        <th colspan="4" style="background-color: #F2F2F2;">{{ 'BEEC.REPORT.LIGHTING' | translate}}</th>
                    </tr> -->

                    <tr>
                        <td> <img style="width: 100%;" src="../../../assets/images/report/lightning-report.png" />
                        </td>
                        <td style="color: red;">{{parentData.lightingvaluebaseline |
                            number:'1.2-2'}}
                            {{parentData.lightingvalueunit}}</td>
                        <td style="color:green ;">{{parentData.lightingvalueees |
                            number:'1.2-2'}}
                            {{parentData.lightingvalueunit}}</td>


                        <td>
                            <ul>
                                <li *ngFor="let rec of parentData.lightingrecommendations;
                                            let i = index">
                                    <p> {{ rec.recommendation}}</p>
                                </li>
                            </ul>
                        </td>
                    </tr>

                    <tr>
                        <th colspan="4" style="background-color: #2F75B5;">{{ 'BEEC.REPORT.PLUGLOADS_HEADING' |
                            translate}}</th>

                    </tr>
                    <tr>
                        <th colspan="2" style="background-color: #F4B084;">{{ 'BEEC.REPORT.BASELINE' | translate}}</th>
                        <th colspan="2" style="background-color: #A9D08E;">{{ 'BEEC.REPORT.ENERGY_EFFICIENCY_STANDARDS'
                            | translate}}</th>

                    </tr>
                    <tr>
                        <th style="background-color: #FCE4D6;">{{ 'BEEC.REPORT.SYSTEM' | translate}}</th>
                        <th style="background-color: #FCE4D6;">{{ 'BEEC.REPORT.PERFORMANCE' | translate}}</th>
                        <th style="background-color: #E2EFDA;">{{ 'BEEC.REPORT.PERFORMANCE' | translate}}</th>
                        <th style="background-color: #E2EFDA;">{{ 'BEEC.REPORT.RECOMMENDATIONS' | translate}}</th>
                    </tr>

                    <!-- <tr>
                        <th colspan="4" style="background-color: #F2F2F2;">{{ 'BEEC.REPORT.PLUGLOADS' | translate}}</th>
                    </tr> -->
                    <tr>
                        <td> <img style="width: 100%;" src="../../../assets/images/report/plugload-report.png" />
                        </td>
                        <td style="color: red;">{{parentData.plugloaddensitybaseline |
                            number:'1.2-2'}}
                            {{parentData.plugloaddensityunit}}</td>
                        <td style="color: green">{{parentData.plugloaddensityees |
                            number:'1.2-2'}}
                            {{parentData.plugloaddensityunit}}</td>


                        <td>
                            <ul>
                                <li *ngFor="let rec of parentData.plugloaddensityrecommendations;
                                            let i = index">
                                    <p> {{ rec.recommendation}}</p>

                                </li>
                            </ul>
                        </td>
                    </tr>
                </thead>
            </table>

            <div class="report-subtitle">
                <p>{{ 'BEEC.REPORT.RECOMMENDATIONS_TITLE' |translate}} </p>
            </div>
            <div>
                <p style="padding-left:2rem" [innerHTML]="'BEEC.REPORT.ADDITIONAL_RECOMMENDATIONS_TEXT' | translate">
                </p>
            </div>
        </div>
    </div>


    <button mat-Flat-button color="primary" (click)="getPDF()">Download Pdf
        <mat-icon *ngIf="isGenerating">
            <mat-spinner color="accent" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
</div>