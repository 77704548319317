<mat-toolbar class="mat-toolbar" >
    <mat-toolbar-row class="d-flex justify-content-between">
        <h3 style="color: darkgreen;">R value Camara_de_aire</h3>
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>


<form [formGroup]="OuterWallFG" novalidate >

    <div >
        <p>{{'BEEC.BUILDINGENV_DETAILS.RVALUE_CUSTOMLAYERS' | translate}}</p>
        <div class=row>

            <p class="w-100 mt-4 col-md-5 column-name">Estaddo de la superficie de la camara de: </p>

            <mat-form-field class="w-100 mt-4 col-md-7" appearance="outline">
                <mat-label>{{'BEEC.COMMON.SELECT' | translate}}</mat-label>
                <mat-select formControlName="surfacecondition">
                    <mat-option *ngFor="let type of surfaceemittance" [value]="type">{{type}}</mat-option>
                </mat-select>
            </mat-form-field><br>
        </div>

        <div class=row>

            <p class="w-100 mt-4 col-md-5 column-name">Espesor de la capa de aire(mm): </p>

            <mat-form-field class="w-100 mt-4 col-md-7" appearance="outline">
                <mat-label>{{'BEEC.COMMON.SELECT' | translate}}</mat-label>
                <mat-select formControlName="airLayerThickness">
                    <mat-option *ngFor="let list of airLayerThicknessList" [value]="list">{{list}}</mat-option>
                </mat-select>
            </mat-form-field><br>
        </div>

    </div>

    <div class="button-dialog" mat-dialog-actions>
        <button mat-raised-button color="primary" style="color: #fff;" type="submit" (click)="postcalculateRAir()" cdkFocusInitial>Click to
        Return</button>
    </div>
</form>