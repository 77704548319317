<mat-toolbar class="mat-toolbar">
    <mat-toolbar-row class="d-flex justify-content-between">
        <h3 class="report-subheading">Assumptions, Limitations and Sources</h3>
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>

</mat-toolbar>

<div class="container">
    <div style="border: 2px solid black;border-radius: 15px;">
        <h4 class="report-subheading">Assumptions and Limitations : </h4>
        <p [innerHTML]="'BEEC.INPUTSUMMARY.INPUTSUMMARY_ASSUMPTIONS' | translate">
        </p>
    </div><br>

    <div style="border: 2px solid black;border-radius: 15px;">
        <h4 class="report-subheading">Sources : </h4>
        <p [innerHTML]="'BEEC.REPORT.SOURCES' | translate">
        <ul style="list-style-type:none;">
            <b>1. General Details:</b>
            <li>1.1 Weather data</li>
            <a href="Climate.OneBuilding.Org">Climate.OneBuilding.Org</a><br>
            <a
                href="https://climate.onebuilding.org/WMO_Region_3_South_America/ARG_Argentina/index.html">https://climate.onebuilding.org/WMO_Region_3_South_America/ARG_Argentina/index.html</a><br>
            1.2 Type of building<br>
            * ASHRAE standard 62.1 2019<br>
            1.4 Occupancy - People<br>
            * ASHRAE standard 62.1 2019<br><br>
            <b>2. Building envelope details</b> <br>
            2.1 - 2.5 R Value, SHGC, associated pictures <br>
            a. Construcciones Sustentables, Envolventes Eficientes by Jorge Daniel Czajkowski, Laboratorio de
            Arquitectura y
            Habitat Sustentable, Facultad de Arquitectura y Urbanismo - UNLP / CIC, Ministerio de Ciencia Tecnologia e
            Innovacion, La Plata 2019.<br>
            b. Conservation of fuel and power, Approved Document L1<br>
            c. Energy Star,
            <a
                href="https://www.energystar.gov/products/building_products/residential_windows_doors_and_skylights/key_product_criteria">https://www.energystar.gov/products/building_products/residential_windows_doors_and_skylights/key_product_criteria</a><br><br>
            <b>3. Lighting</b> <br>
            Images:<br>
            LED :<a href="https://images.app.goo.gl/yCuFMB68paNuhbSv8"> https://images.app.goo.gl/yCuFMB68paNuhbSv8</a>
            <br>
            Compact Fluorescent Lamp (CFL) : <a
                href="https://images.app.goo.gl/bd3cN4sDvk7GLPNR7">https://images.app.goo.gl/bd3cN4sDvk7GLPNR7 </a><br>
            Linear Fluorescent Lamp :<a href="https://images.app.goo.gl/KfpHasCBJPU7n7sK8 ">
                https://images.app.goo.gl/KfpHasCBJPU7n7sK8 </a><br>
            High pressure mercury vapour (HPMV) :<a href="https://images.app.goo.gl/3uK6EAGCG9e6W7Uy8">
                https://images.app.goo.gl/3uK6EAGCG9e6W7Uy8</a> <br>
            High pressure sodium vapour (HPSV) :<a href="https://images.app.goo.gl/RCMvJZKBuBftjuRo6">
                https://images.app.goo.gl/RCMvJZKBuBftjuRo6 </a><br>
            Metal Halide:<a
                href="https://images.app.goo.gl/veDRXkgjoCQv7NLU6">https://images.app.goo.gl/veDRXkgjoCQv7NLU6</a> <br>

            Technical specifications:<br>
            Philips LED
            <a
                href="https://www.lighting.philips.com/main/prof/led-lamps-and-tubes/led-bulbs/standard-led-bulbs#page=&layout=100">https://www.lighting.philips.com/main/prof/led-lamps-and-tubes/led-bulbs/standard-led-bulbs#page=&layout=100
            </a> <br>
            Philips LED
            <a
                href="https://www.lighting.philips.com/main/prof/led-lamps-and-tubes/led-spots/led-par30-s-l#page=&layout=50">https://www.lighting.philips.com/main/prof/led-lamps-and-tubes/led-spots/led-par30-s-l#page=&layout=50
            </a><br>

            Philips CFL
            <a
                href="https://www.lighting.philips.com/main/prof/conventional-lamps-and-tubes/compact-fluorescent-non-integrated#pfpath=0-EP01_GR">https://www.lighting.philips.com/main/prof/conventional-lamps-and-tubes/compact-fluorescent-non-integrated#pfpath=0-EP01_GR</a>
            <br>
            Philips CFL
            <a
                href="https://www.lighting.philips.com/main/prof/conventional-lamps-and-tubes/compact-fluorescent-integrated#pfpath=0-EP01_GR">https://www.lighting.philips.com/main/prof/conventional-lamps-and-tubes/compact-fluorescent-integrated#pfpath=0-EP01_GR</a>
            <br>
            Radium CFL products:
            <a href="https://www.radium.de/en/product-catalogue/fluorescent-lamps/non-integrated-compact-lamps">
                https://www.radium.de/en/product-catalogue/fluorescent-lamps/non-integrated-compact-lamps
            </a> <br>

            Philips FTL
            <a
                href="https://www.lighting.philips.com/main/prof/conventional-lamps-and-tubes/compact-fluorescent-non-integrated#pfpath=0-EP01_GR">https://www.lighting.philips.com/main/prof/conventional-lamps-and-tubes/compact-fluorescent-non-integrated#pfpath=0-EP01_GR</a><br>
            Philips FTL
            <a
                href="https://www.lighting.philips.com/main/prof/conventional-lamps-and-tubes/compact-fluorescent-integrated#pfpath=0-EP01_GR">https://www.lighting.philips.com/main/prof/conventional-lamps-and-tubes/compact-fluorescent-integrated#pfpath=0-EP01_GR
            </a><br>
            Radium FTL products:
            <a href="https://www.radium.de/en/product-catalogue/fluorescent-lamps/non-integrated-compact-lamps">https://www.radium.de/en/product-catalogue/fluorescent-lamps/non-integrated-compact-lamps
            </a><br>

            Philips Incandescent lamps:
            <a
                href="https://www.lighting.philips.com/main/prof/conventional-lamps-and-tubes/incandescent-lamps#pfpath=0-EP01_GR">https://www.lighting.philips.com/main/prof/conventional-lamps-and-tubes/incandescent-lamps#pfpath=0-EP01_GR
            </a> <br><br>
            <b>4. HVAC</b><br>
            4.1 Heating standards and efficiency values<br>
            Hot water radiator: <a
                href="https://www.rehva.eu/rehva-journal/chapter/radiators-convectors-and-energy-efficiency">https://www.rehva.eu/rehva-journal/chapter/radiators-convectors-and-energy-efficiency</a>
            <br>
            Electric radiator :<a href="https://www.energystar.gov/products/heating_cooling">
                https://www.energystar.gov/products/heating_cooling </a><br>
            Gas heaters:<a href="https://www.aga.org/research/regulation/"> https://www.aga.org/research/regulation/
            </a><br>
            Heat Pump:<a href="https://www.energystar.gov/products/heating_cooling">
                https://www.energystar.gov/products/heating_cooling </a><br>
            Central Boilers:<a
                href="https://www.rehva.eu/rehva-journal/chapter/radiators-convectors-and-energy-efficiency">
                https://www.rehva.eu/rehva-journal/chapter/radiators-convectors-and-energy-efficiency</a> <br>

            4.2 Cooling standards and efficiency values<br>
            Industry experts<br>

            4.3 and 4.4 Ventilation and infiltration<br>
            > ASHRAE standard 62.1 2019<br>
            IRAM docments or email conversations (2 ACH assumed) for infiltration rates.<br><br>
            <b>5. Plug loads</b><br>
            National Renewable Energy Laboratory (NREL)<br>
            Energy Star<br>
            EPEAT <br>
            American Council for an Energy Efficient Economy (ACEEE)<br><br>
            <b>Others:</b> <br>
            U.S. Energy Information Administration (EIA) :
            <a
                href="https://www.eia.gov/energyexplained/units-and-calculators/energy-conversion-calculators.php">https://www.eia.gov/energyexplained/units-and-calculators/energy-conversion-calculators.php</a>
            <br>
            CNG Europe:<a
                href="http://cngeurope.com/fuel-calorific-values/">http://cngeurope.com/fuel-calorific-values/</a> <br>

        </ul>
    </div>
</div>