import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reportassumptions',
  templateUrl: './reportassumptions.component.html',
  styleUrls: ['./reportassumptions.component.scss']
})
export class ReportassumptionsComponent implements OnInit {

  constructor(public dialogRef : MatDialogRef<ReportassumptionsComponent>) { }

  ngOnInit(): void {
  }

  onNoClick():void {
    this.dialogRef.close();
  }
}
