<mat-toolbar class="mat-toolbar">
    <mat-toolbar-row class="d-flex justify-content-between">
        <h3 STYLE="font-weight: bold">{{data.title}}:</h3>
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<div >
    <div class="reasons-list" [innerHTML]="data.dialogText">
   
    </div>
   
   <!--  <div class="button-dialog" mat-dialog-actions>
        <button mat-raised-button color="primary" style="color: #fff;" type="button" (click)="onNoClick()">Return</button>
    </div> -->

</div>